import { axiosAdmin } from "../Api/Api";

// Fetch project data by ID
export const fetchProjectById = async (id) => {
  try {
    const response = await axiosAdmin.get(`projectById?id=${id}`);
    return response?.data?.FindProject;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      window.location.replace("/admin/login");
    }
    throw error;
  }
};

// Mark project as completed
export const markProjectAsCompleted = async (id) => {
  try {
    const response = await axiosAdmin.post(`completedprojects?id=${id}`);
    return response;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      window.location.replace("/admin/login");
    }
    throw error;
  }
};

// Delete a specific photo from the project
export const deleteProjectPhoto = async (projectId, photoId) => {
  try {
    const response = await axiosAdmin.delete(`deletePhoto?projectId=${projectId}&photoId=${photoId}`);
    return response;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      window.location.replace("/admin/login");
    }
    throw error;
  }
};
