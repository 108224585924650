import React, { useEffect, useState } from "react";
import ReturnButton from "../../CommonComponents/Return/ReturnButton";
import { axiosAdmin } from "../../../Api/Api";
import { useNavigate } from "react-router-dom";
import Nodata from "../../CommonComponents/Nodata/Nodata";
import Loading from "../../CommonComponents/Loading/Loading";
import Footer from "../../AdminComponents/Footer/Footer";
import DataTable from 'react-data-table-component';

function CompletedProjects() {
  const [projectData, setProjectData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // Fetching data from the backend
  const fetchData = async () => {
    try {
      const response = await axiosAdmin.get("projectList?status=true");
      setProjectData(response?.data?.FindProject);
      setLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        window.location.replace("/admin/login");
      }
      setLoading(false);
    }
  };

  // Data display when mounting
  useEffect(() => {
    fetchData();
  }, []);

  const nav = (id) => {
    navigate("/admin/projectview", { state: { id } });
  };

  // Define columns for the DataTable
  const columns = [
    {
      name: 'Project Number',
      selector: row => row.projectnumber,
      sortable: true,
      width:'150px',
      center: true, // Center the text
    },
    {
      name: 'Project Name',
      selector: row => row.name,
      sortable: true,
      width:'300px',
      left: true, // Center the text
    },
    {
      name: 'Details',
      cell: row => (
        <div className="text-blue-500 cursor-pointer" onClick={() => nav(row._id)}>
          View
        </div>
      ),
      center: true, // Center the text
    },
  ];

  return (
    <div className="flex flex-col justify-between min-h-screen">
      <ReturnButton navigation={"/admin/projectdetails"} />
      {loading ? (
        <Loading />
      ) : (
        <>
          {projectData.length > 0 ? (
            <div className="flex justify-center">
              <div className="relative overflow-x-auto shadow-md sm:rounded-lg max-h-[500px]">
                <DataTable
                  columns={columns}
                  data={projectData}
                  pagination
                  striped
                  highlightOnHover
                  noHeader
                  responsive
                />
              </div>
            </div>
          ) : (
            <Nodata />
          )}
        </>
      )}
      <Footer />
    </div>
  );
}

export default CompletedProjects;
