import React from 'react';
import { useNavigate } from 'react-router-dom';
import ItemCard from '../../CommonComponents/Card/ItemCard';

function Dashboard() {
  const navigate = useNavigate();

  const cardInfo = [
    { name: "PROJECTS", path: '/admin/projectdetails' },
    { name: "LABOURS", path: '/admin/labourdetails' },
    { name: "STAFFS", path: '/admin/staffdetails' },
    { name: "OFFICE", path: '/admin/officedetails' },
    { name: "CONTRACT WORK", path: '/admin/contractdetails' },
    { name: "MATERIAL PURCHASE", path: '/admin/projectlist' },
  ];

  return (
    <div className="flex flex-col items-center justify-between mx-auto p-4">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {cardInfo.map((card) => (
          <ItemCard key={card.name} name={card.name} navigation={() => navigate(card.path)} />
        ))}
      </div>
    </div>
  );
}

export default Dashboard;
