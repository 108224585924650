import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AddNav from "../../CommonComponents/AddNav/AddNav";
import { axiosAdmin } from "../../../Api/Api";
import EditIcon from "@mui/icons-material/Edit";
import Buttons from "../../CommonComponents/Button/Buttons";
import Loading from "../../CommonComponents/Loading/Loading";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import SwalMessage from "../../../utils/SwalMessage";
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import DataTable from 'react-data-table-component';

function Project() {
  const navigate = useNavigate();
  const [projectData, setProjectData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 10;

  const fetchProjects = async () => {
    try {
      setLoading(true);
      let currentPageToUpdate = currentPage;
      if (searchTerm !== '') {
        setCurrentPage(1);
        currentPageToUpdate = 1;
      }
      const response = await axiosAdmin.get("projectList", {
        params: {
          status: false,
          page: currentPageToUpdate,
          searchTerm: searchTerm,
        },
      });
      setProjectData(response.data.FindProject);
      setTotalPages(Math.ceil(response.data.totalCount / itemsPerPage));
      setLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        window.location.replace("/admin/login");
      }
    }
  };

  useEffect(() => {
    fetchProjects();
  }, [currentPage, searchTerm]);

  const handleAddProjectClick = () => {
    navigate("/admin/addproject");
  };

  const handleCompletedProjects = () => {
    navigate("/admin/completedprojects");
  };

  const handleEdit = (projectData) => {
    navigate(`/admin/editproject`, { state: { projectData } });
  };

  const nav = (id, projectname) => {
    navigate("/admin/projectview", { state: { id, projectname } });
  };

  const handleDeleteProject = async (id) => {
    try {
      const admin = true;
      const status = await SwalMessage(`deleteproject?id=${id}`, "Project", "Delete", admin);
      if (status.success) {
        Swal.fire(`Project deleted successfully`, "", "success");
        fetchProjects(); // Re-fetch projects after deletion
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        window.location.replace("/admin/login");
      }
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Define columns for the DataTable
  const columns = [
    {
      name: 'Project Number',
      selector: row => row.projectnumber,
      sortable: true,
      cell: row => (
        <div className="text-center">{row.projectnumber}</div>
      ),
      width: '15%', 
    },
    {
      name: 'Project Name',
      selector: row => row.name,
      sortable: true,
      cell: row => (
        <div className="text-left">{row.name}</div>
      ),
      width: '35%', 
    },
    {
      name: 'Status',
      selector: row => row.status,
      sortable: true,
      cell: row => (
        <div>{row.status}</div>
      ),
      width: '30%', 
    },
    {
      name: 'Details',
      cell: row => (
        <div className="text-center">
          <span
            className="text-blue-500 cursor-pointer hover:underline"
            onClick={() => nav(row._id, row.name)}
          >
            View
          </span>
        </div>
      ),
      width: '10%', 
    },
    {
      name: 'Actions',
      cell: row => (
        <div className="flex justify-center space-x-2">
          <EditIcon
            onClick={() => handleEdit(row)}
            className="text-yellow-600 cursor-pointer w-3 h-3"
          />
          <DeleteIcon
            onClick={() => handleDeleteProject(row._id)}
            className="text-red-600 cursor-pointer w-3 h-3"
          />
        </div>
      ),
      width: '10%', 
    },
  ];

  return (
    <>
      <div className="w-full flex flex-col lg:flex-row mb-4">
        <div className="w-full">
          <AddNav
            name="+ ADD NEW PROJECT"
            click={handleAddProjectClick}
            value={searchTerm}
            onChange={handleSearch}
            navigation={"/admin/dashboard"}
            viewClick={handleCompletedProjects}
            twoButton={true}
            viewButtonName="COMPLETED PROJECTS"
          />
        </div>
      </div>

      {loading ? (
        <Loading />
      ) : (
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-11 mx-6 max-h-[600px]">
          <DataTable
            columns={columns}
            data={projectData}
            pagination={false} 
            noHeader
            highlightOnHover
            striped
          />
        </div>
      )}

      {/* Custom Pagination */}
      <div className="flex justify-center mt-4">
        <button
          className="mx-2 px-3 py-1 bg-gray-200 text-gray-500 rounded"
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <KeyboardArrowLeft />
        </button>
        <span>{currentPage} of {totalPages}</span>
        <button
          className="mx-2 px-3 py-1 bg-gray-200 text-gray-500 rounded"
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          <KeyboardArrowRight />
        </button>
      </div>
    </>
  );
}

export default Project;
