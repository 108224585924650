import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { fetchProjectById, markProjectAsCompleted, deleteProjectPhoto } from "../../../services/ProjectServices";
import ReturnButton from "../../CommonComponents/Return/ReturnButton";
import Buttons from "../../CommonComponents/Button/Buttons";
import SingleView from "../../AdminComponents/Materials/SingleView";
import PhotoAddModal from "./PhotoAddModal";
import Swal from "sweetalert2";
import CommonCard from "../../CommonComponents/CommonCard/CommonCard";
import { useNavigate } from "react-router-dom";
import Payments from "../../AdminComponents/Project/Payments";
import SingleByProject from "../Contract/SingleByProject";
import Loading from "../../CommonComponents/Loading/Loading";

const SingleViewProject = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [projectData, setProjectData] = useState(null);

  const id = location?.state?.id;
  const projectname = location?.state?.projectname;

  // Fetching data from backend
  const fetchData = async () => {
    try {
      const project = await fetchProjectById(id);
      setProjectData(project);
    } catch (error) {
      console.error("Error fetching project data:", error);
    }
  };

  const handleCompletedProjects = async () => {
    try {
      const result = await Swal.fire({
        title: "Is the project completed?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, completed!",
      });

      if (result.isConfirmed) {
        await markProjectAsCompleted(id);
        Swal.fire("Work completed", "The project is added to completed projects", "success")
          .then(() => navigate("/admin/projectdetails"));
      }
    } catch (error) {
      console.error("Error marking project as completed:", error);
    }
  };

  const handleDeletePhoto = async (photoId) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "This will permanently delete the photo!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (result.isConfirmed) {
        await deleteProjectPhoto(id, photoId);
        Swal.fire("Deleted!", "The photo has been deleted.", "success");
        fetchData(); // Refresh project data to reflect deletion
      }
    } catch (error) {
      console.error("Error deleting photo:", error);
    }
  };

  // Data displaying when mounting
  useEffect(() => {
    fetchData();
  }, [id]);

  return (
    <>
      <ReturnButton navigation={"/admin/projectdetails"} />
      {projectData === null ? (
        <Loading />
      ) : (
        <>
          <div>
            {projectData &&
              projectData.map((project, index) => (
                <div key={index} className="max-w-5xl mx-auto p-6 mt-6">
                  <h1 className="flex justify-center font-bold text-3xl uppercase">
                    {project.name}
                  </h1>
                  {project.photos.length > 0 ? (
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-8">
                      {project.photos.map((photo, i) => (
                        <div key={i} className="relative mb-6 md:mb-0">
                          <img
                            src={photo}
                            alt={`Project Image ${i + 1}`}
                            className="w-full h-96 rounded-lg"
                          />
                          <button
                            onClick={() => handleDeletePhoto(photo)}
                            className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-2"
                          >
                            Delete
                          </button>
                        </div>
                      ))}
                    </div>
                  ) : project.isCompleted === false ? (
                    <p className="text-red-500">Please add project photos</p>
                  ) : (
                    ""
                  )}

                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 mt-4">
                    <div>
                      <CommonCard value={project.status} label="Status" />
                    </div>
                    <div>
                      <CommonCard value={project.pending} label="Pending" />
                    </div>
                    <div>
                      <CommonCard value={project.upnext} label="Up-next" />
                    </div>
                    <div>
                      <CommonCard value={project.supervisorname} label="Supervisor name" />
                    </div>
                    <div>
                      <CommonCard value={project.notes} label="Notes" />
                    </div>
                    <div>
                      {project.isCompleted === false ? (
                        <div className="flex justify-end gap-4 sm:mt-11">
                          <div>
                            <PhotoAddModal projectId={project._id} />
                          </div>
                          <div>
                            <Buttons
                              name="WORK COMPLETED"
                              click={handleCompletedProjects}
                            />
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              ))}
          </div>

          <div className="flex flex-wrap justify-center mt-14">
            <p className="font-serif font-bold text-[30px]">
              Project Received Cash And Date
            </p>
          </div>
          <Payments projectId={id} />

          <div className="flex flex-wrap justify-center mt-14">
            <p className="font-serif font-bold text-[30px]">
              Material Used For This Project
            </p>
          </div>
          <SingleView id={id} />

          <div className="flex flex-wrap justify-center mt-14">
            <p className="font-serif font-bold text-[30px]">
              Contracts Works of This Project
            </p>
          </div>
          <SingleByProject projectname={projectname} />
        </>
      )}
    </>
  );
};

export default SingleViewProject;
