import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import { styled } from '@mui/system';

// Styled components for a more customized design
const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: 350,
  width: '100%',
  borderRadius: '16px',
  boxShadow: '0 4px 10px rgba(0, 0, 0, 0.15)',
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 8px 20px rgba(0, 0, 0, 0.2)',
  },
}));

const TitleTypography = styled(Typography)({
  fontWeight: 'bold',
  textAlign: 'center',
  color: 'black',
  marginBottom: '10px',
});

const ViewButton = styled(Button)({
  color: 'blue',
  borderRadius: '20px',
  padding: '8px 16px',
  width: '100%',
});

function ItemCard({ name, navigation }) {
  return (
    <StyledCard>
      <CardActionArea>
        <CardContent>
          <TitleTypography variant="h6" component="div">
            {name}
          </TitleTypography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <ViewButton size="small" onClick={navigation}>
          VIEW
        </ViewButton>
      </CardActions>
    </StyledCard>
  );
}

export default ItemCard;
